import { styled } from "@mui/material";
import PageHeaderWithNav from "Components/Utilites/PageHeaderWithNav";
import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Root = styled("div")((props) => ({
  marginLeft: props.theme.spacing(2),
  marginRight: props.theme.spacing(2),
  [props.theme.breakpoints.only("xs")]: {
    marginLeft: props.theme.spacing(1),
    marginRight: props.theme.spacing(1),
  },
}));

const LIST_PAGE_URL = [
  {
    path: "/master/user",
    header: "Master User",
    subheader: "This page use to manage User",
  },
  {
    path: "/master/city",
    header: "Master City",
    subheader: "This page use to manage Master City",
  },
  {
    path: "/master/bankaccount",
    header: "Master Bank Account",
    subheader: "This page use to manage Master Bank Account",
  },
  {
    path: "/master/item/sellable/detail",
    header: "Master Item Penjualan (Detail)",
  },
  {
    path: "/master/item/sellable/edit",
    header: "Master Item Penjualan (Edit)",
  },
  {
    path: "/master/item/sellable",
    header: "Master Item Penjualan",
  },
  {
    path: "/master/item",
    header: "Master Item",
    subheader: "This page use to manage Master Item",
  },
  {
    path: "/master/ekspedisi",
    header: "Master Expedition",
  },
  {
    path: "/master/customer",
    header: "Master Customer",
  },
  {
    path: "/so/create",
    header: "Buat Sales Order",
  },
  {
    path: "/so/list",
    header: "Daftar Sales Order",
    subheader: "Daftar Sales Order yang pernah dibuat",
  },
  {
    path: "/so/detail",
    header: "Detail Sales Order",
    subheader: "Informasi detail dokumen Sales Order",
    customTitle: {
      title: "Sales Order ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/so/edit",
    header: "Edit Sales Order",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/so/report",
    header: "Report Sales Order",
  },
  {
    path: "/suratjalan/list",
    header: "Daftar Surat Jalan",
  },
  {
    path: "/suratjalan/salesorder/create",
    header: "Buat Surat Jalan",
  },
  {
    path: "/suratjalan/salesorder/edit",
    header: "Edit Surat Jalan",
  },
  {
    path: "/suratjalan/detail",
    header: "Detail Surat Jalan",
    customTitle: {
      title: "Surat Jalan ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invoice/create",
    header: "Buat Faktur Penjualan",
  },
  {
    path: "/invoice/list/",
    header: "Daftar Faktur Penjualan",
  },
  {
    path: "/invoice/detail",
    header: "Detail Faktur Penjualan",
    customTitle: {
      title: "Invoice ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invoice/edit",
    header: "Edit Faktur Penjualan",
    customTitle: {
      title: "Edit Invoice ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/retur/create",
    header: "Buat Retur",
  },
  {
    path: "/retur/detail",
    header: "Detail Retur",
    customTitle: {
      title: "Retur ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/retur/list",
    header: "Daftar Retur / BS",
  },
  {
    path: "/retur/edit",
    header: "Edit Retur",
    customTitle: {
      title: "Edit Retur ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/receipt/create",
    header: "Buat Tanda Terima",
  },
  {
    path: "/receipt/list",
    header: "Daftar Tanda Terima",
  },
  {
    path: "/receipt/detail",
    header: "Detail Tanda Terima",
    customTitle: {
      title: "Tanda Terima ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/receipt/edit",
    header: "Edit Tanda Terima",
    customTitle: {
      title: "Edit Tanda Terima ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/pelunasan/create",
    header: "Buat Pembayaran/Pelunasan Invoice",
  },
  {
    path: "/pelunasan/detail",
    header: "Detail Pembayaran/Pelunasan Invoice",
    customTitle: {
      title: "Pembayaran ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/pelunasan/list",
    header: "Daftar Pembayaran/Pelunasan Invoice",
  },
  {
    path: "/pelunasan/edit",
    header: "Edit Pembayaran/Pelunasan Invoice",
    customTitle: {
      title: "Edit Pembayaran ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/report/penjualan",
    header: "Report Penjualan",
  },
  {
    path: "/report/piutangdanpelunasan",
    header: "Report Piutang & Pembayaran/Pelunasan",
  },
  {
    path: "/report/returpenjualan",
    header: "Report Retur Penjualan",
  },
  {
    path: "/report/outstanding/delivery",
    header: "Outstanding Delivery Report",
  },
  {
    path: "/report/outstanding/delivery/itemsummary",
    header: "Outstanding Delivery Report",
  },
  {
    path: "/report/salesinv/aging",
    header: "Aging Report",
  },
  {
    path: "/ttd/create",
    header: "Buat Tanda Terima Deposit",
  },
  {
    path: "/ttd/list",
    header: "Daftar Tanda Terima Deposit",
  },
  {
    path: "/ttd/detail",
    header: "Detail Tanda Terima Deposit",
    customTitle: {
      title: "Deposit DO ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/ttd/edit",
    header: "Edit Tanda Terima Deposit",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/do/create",
    header: "Buat Pesanan DO",
  },
  {
    path: "/do/detail",
    header: "Detail Pesanan DO",
    customTitle: {
      title: "DO ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/do/list",
    header: "Daftar Pesanan DO",
  },
  {
    path: "/do/edit",
    header: "Edit Pesanan DO",
    customTitle: {
      title: "Edit DO ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/do/report",
    header: "Report Pesanan DO",
  },
  {
    path: "/dso/detail",
    header: "Detail Deposit SO",
    customTitle: {
      title: "Deposit SO ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/dso/list",
    header: "Daftar Deposit SO",
  },
  {
    path: "/master/material/manage",
    header: "Master Material",
  },
  {
    path: "/master/vendor/manage",
    header: "Master Supplier",
  },
  {
    path: "/pr/create",
    header: "Buat Permintaan Pembelian (PR)",
  },
  {
    path: "/pr/detail",
    header: "Detail Permintaan Pembelian (PR)",
    customTitle: {
      title: "Detail Permintaan ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/pr/list",
    header: "Daftar Permintaan Pembelian (PR)",
  },
  {
    path: "/pr/edit",
    header: "Edit Permintaan Pembelian (PR)",
  },
  {
    path: "/po/create",
    header: "Buat Purchase Order (PO)",
  },
  {
    path: "/po/list",
    header: "Daftar Purchase Order (PO)",
  },
  {
    path: "/po/detail",
    header: "Detail Purchase Order (PO)",
    customTitle: {
      title: "Detail ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/po/edit",
    header: "Edit Purchase Order (PO)",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invr-po/create",
    header: "Buat Penerimaan Faktur (PO)",
  },
  {
    path: "/invr-po/list",
    header: "Daftar Penerimaan Faktur (PO)",
  },
  {
    path: "/invr-po/detail",
    header: "Detail Penerimaan Faktur (PO)",
    customTitle: {
      title: "Detail ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invr-po/edit",
    header: "Edit Penerimaan Faktur (PO)",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/gr-po/create",
    header: "Buat Penerimaan Barang (PO)",
  },
  {
    path: "/gr-po/list",
    header: "Daftar Penerimaan Barang (PO)",
  },
  {
    path: "/gr-po/detail",
    header: "Detail Penerimaan Barang (PO)",
    customTitle: {
      title: "Detail ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/gr-po/edit",
    header: "Edit Penerimaan Barang (PO)",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invp-po/create",
    header: "Buat Pembayaran Faktur (PO)",
  },
  {
    path: "/invp-po/list",
    header: "Daftar Pembayaran Faktur (PO)",
  },
  {
    path: "/invp-po/detail",
    header: "Detail Pembayaran Faktur (PO)",
    customTitle: {
      title: "Detail ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/invp-po/edit",
    header: "Edit Pembayaran Faktur (PO)",
    customTitle: {
      title: "Edit ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/pttdc/create",
    header: "Buat Rekap Deposit DO",
  },
  {
    path: "/pttdc/detail",
    header: "Data Rekap Deposit DO",
  },
  {
    path: "/pttdc/list",
    header: "Daftar Rekap Deposit DO",
  },
  {
    path: "/seller/list",
    header: "Daftar Salesman",
  },
  {
    path: "/seller/mappingcustomer",
    header: "Pilih Pelanggan Untuk Sales",
  },
  {
    path: "/seller/salescustomerlist",
    header: "Daftar Pelanggan Untuk Sales",
  },
  {
    path: "/fp-do/detail",
    header: "Detail Invoice Deposit Order",
    customTitle: {
      title: "Invoice ###",
      keySearchParams: "oin",
    },
  },
  {
    path: "/promo",
    header: "PROMO",
  },
];

function useQuery(searchParams) {
  return new URLSearchParams(searchParams);
}

const BackNavWithTitle = () => {
  const currentLocation = useLocation();
  const query = useQuery(currentLocation.search);

  const pageInfo = LIST_PAGE_URL.find(({ path }) => currentLocation.pathname.toLowerCase().startsWith(path));

  useEffect(() => {
    if (!pageInfo) return;

    if (pageInfo.customTitle) {
      const v = query.get(pageInfo.customTitle.keySearchParams);
      document.title = pageInfo.customTitle.title.replace("###", v);
      return;
    }
    document.title = pageInfo.title || pageInfo.header;
  }, [pageInfo, currentLocation, query]);

  // if (!pageInfo || (pageInfo && !pageInfo.header)) return (
  //     <Box height={4}/>
  // );

  return (
    <>
      <Root>
        <PageHeaderWithNav
          title={pageInfo ? pageInfo.header : currentLocation.pathname.replace("/", "")}
          //  subtitle={pageInfo.subheader}
        />
        {/* <Divider/> */}
      </Root>
    </>
  );
};

export default memo(BackNavWithTitle);
