import { LinearProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import LDTheme from "Components/LDTheme";
import MainDrawer from "Components/MainDrawer";
import moment from "moment";
import SalesOrderRoute from "Page/SalesOrder";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorBoundary from "./Components/ErrorBoundary";
import { CenterComponent } from "./Components/Utilites";
import { localeConfig } from "./Config/moment_config";
import { verifyAccount } from "./Helpers/auth";
import MyContext from "./Helpers/mainContext";
import { getErrorFetchResponse, getToken } from "./Helpers/tools";
import ChangePassword from "./Page/ChangePasswordPage";
import LoginPage from "./Page/LoginPage";
import NotFoundPage from "./Page/NotFoundPage";
import "./types.js";

moment.updateLocale("id", localeConfig);

/* MASTER DATA COMPONENT */
// const PageMasterCustomer = lazy(() => import('./Page/Customer/CustomerListPage'));
const PageMasterCustomer = lazy(() => import("./Page/Customer"));
const PageMasterItem = lazy(() => import("./Page/Item/ItemListPage"));
const PageMasterExpedition = lazy(() => import("./Page/Expedition/ExpeditionListPage"));
const PageMasterCity = lazy(() => import("./Page/City/CityListPAge"));
const PageListMasterUser = lazy(() => import("./Page/MasterUserList"));
const PageListMasterRekening = lazy(() => import("./Page/BankAccountList"));
const MasterSellableItem = lazy(() => import("Page/Item/Sellable_Item"));
/* SURAT JALAN */
const ListOfSuratJalanPage = lazy(() => import("./Page/SuratJalan/ListOfSuratJalanPage"));
const DetailSuratJalanPage = lazy(() => import("./Page/SuratJalan/DetailSuratJalanPage"));
const CreateSuratJalanPage = lazy(() => import("./Page/SuratJalan/CreateSuratJalanPage"));
const CreateSuratJalanSOPage = lazy(() => import("./Page/SuratJalan/CreateSuratJalanSO"));
const EditSuratJalanSOPage = lazy(() => import("./Page/SuratJalan/EditSuratJalanSO"));
// const EditSuratJalanPage = lazy(() => import('./Page/SuratJalan/edit'));
// const ReportSuratJalanPage = lazy(() => import('./Page/SuratJalan/ReportSuratJalanPage'));
/* INVOICE / FAKTUR COMPONENT*/
const PageCreateFaktur = lazy(() => import("./Page/Faktur/CreateFakturPage"));
const PageListFaktur = lazy(() => import("./Page/Faktur/ListFakturPage"));
const PageDetailFaktur = lazy(() => import("./Page/Faktur/DetailFakturPage"));
const EditFakturPage = lazy(() => import("./Page/Faktur/EditFakturPage"));
const PageFakturDibawahJuli = lazy(() => import("./Page/Faktur/HapusBawahJuli"));
// const ReportFakturPage = lazy(() => import('./Page/Faktur/ReportFakturPage'));
/* RETUR / BS COMPONENT*/
const PageCreateBS = lazy(() => import("./Page/Retur/CreateReturPage"));
const PageDetailBS = lazy(() => import("./Page/Retur/DetailReturPage"));
const EditReturPage = lazy(() => import("./Page/Retur/EditReturPage"));
const PageListBSDocument = lazy(() => import("./Page/Retur/ReturListPage"));
/* RECEIPT / TANDA TERIMA COMPONENT */
const PageCreateTandaTerima = lazy(() => import("./Page/Receipt/CreateReceiptPage"));
const ListReceiptPage = lazy(() => import("./Page/Receipt/ListReceiptPage"));
const DetailReceiptPage = lazy(() => import("./Page/Receipt/DetailReceiptPage"));
const EditReceiptPage = lazy(() => import("./Page/Receipt/EditReceiptPage"));
/* REPORT COMPONENT */
const ReportOutstandingDeliveryPage = lazy(() => import("./Page/Report/OutstandingDeliveryPage"));
/* GL ACCOUNT COMPONENT */
const ListChartOfAccountPage = lazy(() => import("./Page/ChartOfAccounts/ListChartOfAccountPage"));
const AccountsDetailTransactionPage = lazy(() => import("./Page/ChartOfAccounts/AccountsTransactionDetailsPage"));
const FormCreateAccountsPage = lazy(() => import("./Page/ChartOfAccounts/FormCreateAccountsPage"));
/* PELUNASAN / PF COMPONENT */
// const CreatePelunasanPage = lazy(() => import('./Page/Pelunasan/CreatePelunasanPage'));
// const DetailPelunasanPage = lazy(() => import('./Page/Pelunasan/DetailPelunasanPage'));
// const CreatePelunasan2 = lazy(() => import('./Page/Pelunasan/CreatePelunasan2'));
const CreatePelunasan3 = lazy(() => import("./Page/Pelunasan/Create3"));
const DetailPelunasan2 = lazy(() => import("./Page/Pelunasan/DetailPelunasan2"));
const ListPelunasanPage = lazy(() => import("./Page/Pelunasan/ListPelunasanPage"));
// const EditPelunasanPage = lazy(() => import('./Page/Pelunasan/EditPelunasanPage'));
// const EditPelunasan2 = lazy(() => import('./Page/Pelunasan/EditPelunasan2'));
const EditPelunasan3 = lazy(() => import("./Page/Pelunasan/Edit3"));
// const CreatePelunasan2 = lazy(() => import('./Page/Pelunasan/CreatePelunasan2'));
const FixJurnalEntriPelunasanPage = lazy(() => import("./Page/Pelunasan/FixJurnalEntri"));
/* DASHBOARD COMPONENT */
const DashboardPage = lazy(() => import("./Page/Dashboard"));
/* MASTER MENU PAGE */
const CreateMasterMenuPage = lazy(() => import("./Page/Master_menu/CreateMasterMenuPage"));
/* DO */
const CreateDOPage = lazy(() => import("./Page/DO/CreateDOPage"));
const DetailDOPage = lazy(() => import("./Page/DO/DetailDOPage"));
const ListDOPage = lazy(() => import("./Page/DO/ListDOPage"));
const EditDOPage = lazy(() => import("./Page/DO/EditDOPage"));
const ReportDOPage = lazy(() => import("./Page/DO/ReportDOPage"));
/* FP-DO */
const DepositOrderInvoiceRoute = lazy(() => import("./Page/FP-DO"));
/* TTD */
const TTDCreatePage = lazy(() => import("./Page/TTD/create"));
const TTDDetailPage = lazy(() => import("./Page/TTD/detail"));
const TTDListPage = lazy(() => import("./Page/TTD/list"));
const TTDEditPage = lazy(() => import("./Page/TTD/edit"));
const PTTDCCreatePage = lazy(() => import("./Page/PTTDC/create"));
const PTTDCDetailPage = lazy(() => import("./Page/PTTDC/detail"));
const PTTDCListPage = lazy(() => import("./Page/PTTDC/list"));
/* DSO */
const DSOListPage = lazy(() => import("./Page/DSO/List"));
const DSODetailPage = lazy(() => import("./Page/DSO/Detail"));
/* REPORT */
const ReportPenjualanPage = lazy(() => import("./Page/Report/ReportPenjualanPage"));
const ReportPiutangDanPelunasanPage = lazy(() => import("./Page/Report/ReportPiutangDanPelunasanPage"));
const ReportReturPage = lazy(() => import("./Page/Report/ReportReturPage"));
const OutstandingDeliveryItemSummPage = lazy(() => import("./Page/Report/OutstandingDeliveryItemSummPage"));
const AgingReportSalesInv = lazy(() => import("./Page/Report/AgingReportSalesInv"));
/* MASTER VENDOR */
// const ManageMasterVendor = lazy(() => import('./Page/MasterVendor/ManageMasterVendor'));
/* MASTER ITEM UNTUK PRODUCTION */
// const ManageMasterMaterialPage = lazy(() => import('./Page/MasterMaterial/ManageMasterMaterialPage'));
/* PURCHASE REQUISITION */
// const CreatePurchaseRequisitionPage = lazy(() => import('Page/PurchaseRequisition/Create'));
// const DetailPurchaseRequisitionPage = lazy(() => import('Page/PurchaseRequisition/Detail'));
// const ListPurchaseRequisitionPage = lazy(() => import('Page/PurchaseRequisition/List'));
// const EditPurchaseRequisitionPage = lazy(() => import('Page/PurchaseRequisition/Edit'));
/* PURCHASE ORDER MODULE */
// const CreatePurchaseOrderPage = lazy(() => import('Page/PurchaseOrder/Create'));
// const ListPurchaseOrderPage = lazy(() => import('Page/PurchaseOrder/List'));
// const DetailPurchaseOrderPage = lazy(() => import('Page/PurchaseOrder/Detail'));
// const EditPurchaseOrderPage = lazy(() => import('Page/PurchaseOrder/Edit'));
/* GOODS RECEIPT PO MODULE */
// const GoodsReceiptNotePageCreate = lazy(() => import('Page/GR-PO/create'));
// const GoodsReceiptNoteDetailPage = lazy(() => import('Page/GR-PO/detail'));
// const GoodsReceiptNoteListPage = lazy(() => import('Page/GR-PO/list'));
// const GoodsReceiptNoteEditPage = lazy(() => import('Page/GR-PO/edit'));
/* INVOICE RECEIPT PO MODULE */
// const InvoiceReceiptCreatePage = lazy(() => import('Page/INVR-PO/create'));
// const InvoiceReceiptListPage = lazy(() => import('Page/INVR-PO/list'));
// const InvoiceReceiptDetailPage = lazy(() => import('Page/INVR-PO/detail'));
// const InvoiceReceiptEditPage = lazy(() => import('Page/INVR-PO/edit'));
/* INVOICE PAYMENT MODULE */
// const CreateINVPPage = lazy(() => import('Page/INVP-PO/create'));
// const DetailINVPPage = lazy(() => import('Page/INVP-PO/detail'));
// const EditINVPPage = lazy(() => import('Page/INVP-PO/edit'));
// const ListINVPPage = lazy(() => import('Page/INVP-PO/list'));
// const ExcelToAccuratePage = lazy(() => import('./Page/ExcelToAccurate'));
/* ACCURATE */
const MappingItemToAccuratePage = lazy(() => import("./Page/Item/mappingitem"));
const PageSaldoAwalPiutang = lazy(() => import("./Page/SaldoAwal/piutang"));
const AccurateMainPage = lazy(() => import("./Page/Accurate"));
/* SELLER */
const MasterSellerRoute = lazy(() => import("./Page/SELLER"));
/* PROMO */
const PromoModuleRoute = lazy(() => import("./Page/PROMO"));

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#3b83f6',
//       light: '#4ba1ff',
//       dark: '#395ecf',
//       contrastText: '#ffffff'
//     },
//     secondary: {
//       main: '#683ab7',
//       light: '#9675cd',
//       dark: '#522da8',
//       contrastText: '#ffffff'
//     },
//   },
// });

const FallBackComponent = React.memo(() => (
  <CenterComponent>
    <h4>Loading Page</h4>
    <div style={{ padding: 8, width: 220 }}>
      <LinearProgress />
    </div>
  </CenterComponent>
));

function App() {
  const logged = Boolean(getToken());

  const authorizedElement = (element, isAuthUser) => {
    if (isAuthUser) return element;

    const current_url = window.location.pathname + window.location.search + window.location.hash;
    if (!sessionStorage.getItem("_target_url")) {
      sessionStorage.setItem("_target_url", current_url);
    }

    return <Navigate to="/Login" replace />;
  };

  const [user, setUser] = React.useState({
    username: "",
  });

  React.useEffect(() => {
    verifyAccount()
      .then((result) => {
        setUser(result);
      })
      .catch((err) => {
        const errorResponse = getErrorFetchResponse(err);
        if (errorResponse.status === 401) {
          // window.alert("Silahkan untuk melakukan login terlebih dahulu");
        } else if (errorResponse.status === 403) {
          window.alert("Maaf sesi login anda sudah berakhir, silahkan untuk melakukan login ulang");
        }
      });
  }, []);

  const routing = () => (
    <ErrorBoundary>
      <Suspense fallback={<FallBackComponent />}>
        <Routes>
          {/* <Route path="/" element={authorizedElement(<HomePage/>)} caseSensitive/> */}
          <Route path="/" element={logged ? <Navigate to="/Dashboard" replace /> : <Navigate to="/Login" replace />} caseSensitive />
          <Route path="/Login" element={logged ? <Navigate to="/Dashboard" replace /> : <LoginPage />} caseSensitive />
          <Route path="/Dashboard" element={authorizedElement(<DashboardPage />, logged)} caseSensitive />
          <Route path="/Account/Password" element={authorizedElement(<ChangePassword />, logged)} />
          {/* MASTER ROUTING */}
          <Route path="/Master/User" element={authorizedElement(<PageListMasterUser />, logged)} />
          <Route path="/Master/City" element={authorizedElement(<PageMasterCity />, logged)} />
          {/* <Route path="/Master/Customer" element={authorizedElement(<PageMasterCustomer/>, logged)}/> */}
          <Route path="/Master/Customer" element={authorizedElement(<PageMasterCustomer />, logged)} />
          <Route path="/Master/Item" element={authorizedElement(<PageMasterItem />, logged)} />
          <Route path="/Master/Ekspedisi" element={authorizedElement(<PageMasterExpedition />, logged)} />
          <Route path="/Master/BankAccount" element={authorizedElement(<PageListMasterRekening />, logged)} />
          <Route path="/master/item/sellable/*" element={authorizedElement(<MasterSellableItem />, logged)} />
          {/* MASTER VENDOR */}
          {/* <Route path="/master/vendor/manage" element={authorizedElement(<ManageMasterVendor/>, logged)}/> */}
          {/* MASTER MATERIAL */}
          {/* <Route path="/master/material/manage" element={authorizedElement(<ManageMasterMaterialPage/>, logged)}/> */}
          {/* MASTER MENU */}
          <Route path="/master/menu/user/settings" element={authorizedElement(<CreateMasterMenuPage />, logged)} />
          {/* SALES ORDER ROUTING */}
          <Route path="SO/*" element={authorizedElement(<SalesOrderRoute />, logged)} />
          {/* SURAT JALAN ROUTING */}
          <Route path="/SuratJalan/List/:status" element={authorizedElement(<ListOfSuratJalanPage />, logged)} />
          {/* <Route path="/SuratJalan/List" element={authorizedElement(<ListOfSuratJalanPage/>, logged)}/> */}
          <Route path="/SuratJalan/Detail" element={authorizedElement(<DetailSuratJalanPage />, logged)} />
          <Route path="/SuratJalan/create" element={authorizedElement(<CreateSuratJalanPage />, logged)} />
          <Route path="/suratjalan/salesorder/create" element={authorizedElement(<CreateSuratJalanSOPage />, logged)} />
          <Route path="/suratjalan/salesorder/edit" element={authorizedElement(<EditSuratJalanSOPage />, logged)} />
          {/* <Route path="/SuratJalan/edit" element={authorizedElement(<EditSuratJalanPage/>, logged)}/> */}
          {/* <Route path="/SuratJalan/report" element={authorizedElement(<ReportSuratJalanPage/>)}/>, logged */}
          {/* FAKTUR ROUTING */}
          <Route path="/Invoice/Create" element={authorizedElement(<PageCreateFaktur />, logged)} />
          <Route path="/Invoice/List/:status" element={authorizedElement(<PageListFaktur />, logged)} />
          <Route path="/Invoice/Detail" element={authorizedElement(<PageDetailFaktur />, logged)} />
          <Route path="/Invoice/Edit" element={authorizedElement(<EditFakturPage />, logged)} />
          <Route path="/Invoice/bawahsatu" element={authorizedElement(<PageFakturDibawahJuli />, logged)} />
          {/* <Route path="/Invoice/report" element={authorizedElement(<ReportFakturPage/>)}/>, logged */}
          {/* BS ROUTING */}
          <Route path="/Retur/Create" element={authorizedElement(<PageCreateBS />, logged)} />
          <Route path="/Retur/Detail" element={authorizedElement(<PageDetailBS />, logged)} />
          <Route path="/Retur/List/:status" element={authorizedElement(<PageListBSDocument />, logged)} />
          <Route path="/Retur/Edit" element={authorizedElement(<EditReturPage />, logged)} />
          {/* TANDA TERIMA ROUTING */}
          <Route path="/Receipt/Create" element={authorizedElement(<PageCreateTandaTerima />, logged)} />
          <Route path="/Receipt/List/:status" element={authorizedElement(<ListReceiptPage />, logged)} />
          <Route path="/Receipt/Detail" element={authorizedElement(<DetailReceiptPage />, logged)} />
          <Route path="/Receipt/Edit" element={authorizedElement(<EditReceiptPage />, logged)} />
          {/* CHART OF ACCOUNTS */}
          <Route path="/Accounts/List" element={authorizedElement(<ListChartOfAccountPage />, logged)} />
          <Route path="/Accounts/Create" element={authorizedElement(<FormCreateAccountsPage />, logged)} />
          <Route path="/Accounts/Transactions/:account_id" element={authorizedElement(<AccountsDetailTransactionPage />, logged)} />
          {/* PELUNASAN */}
          <Route path="/Pelunasan/Create" element={authorizedElement(<CreatePelunasan3 />, logged)} />
          <Route path="/Pelunasan/Detail" element={authorizedElement(<DetailPelunasan2 />, logged)} />
          <Route path="/Pelunasan/List/:status" element={authorizedElement(<ListPelunasanPage />, logged)} />
          <Route path="/Pelunasan/Edit" element={authorizedElement(<EditPelunasan3 />, logged)} />
          <Route path="/Pelunasan/jurnalentri/fix" element={authorizedElement(<FixJurnalEntriPelunasanPage />, logged)} />
          {/* REPORT */}
          <Route path="/report/penjualan" element={authorizedElement(<ReportPenjualanPage />, logged)} />
          <Route path="/report/piutangdanpelunasan" element={authorizedElement(<ReportPiutangDanPelunasanPage />, logged)} />
          <Route path="/report/returpenjualan" element={authorizedElement(<ReportReturPage />, logged)} />
          <Route path="/report/Outstanding/Delivery" element={authorizedElement(<ReportOutstandingDeliveryPage />, logged)} />
          <Route path="/report/outstanding/delivery/itemsummary" element={authorizedElement(<OutstandingDeliveryItemSummPage />, logged)} />
          <Route path="/report/salesinv/aging" element={authorizedElement(<AgingReportSalesInv />, logged)} />
          {/* TTD */}
          <Route path="/TTD/create" element={authorizedElement(<TTDCreatePage />, logged)} />
          <Route path="/TTD/list/:status" element={authorizedElement(<TTDListPage />, logged)} />
          <Route path="/TTD/detail" element={authorizedElement(<TTDDetailPage />, logged)} />
          <Route path="/TTD/edit" element={authorizedElement(<TTDEditPage />, logged)} />
          {/* PTTDC */}
          <Route path="/PTTDC/create" element={authorizedElement(<PTTDCCreatePage />, logged)} />
          <Route path="/PTTDC/detail" element={authorizedElement(<PTTDCDetailPage />, logged)} />
          <Route path="/PTTDC/list" element={authorizedElement(<PTTDCListPage />, logged)} />
          {/* DO */}
          <Route path="/DO/create" element={authorizedElement(<CreateDOPage />, logged)} />
          <Route path="/DO/detail" element={authorizedElement(<DetailDOPage />, logged)} />
          <Route path="/DO/list/:status" element={authorizedElement(<ListDOPage />, logged)} />
          <Route path="/DO/edit" element={authorizedElement(<EditDOPage />, logged)} />
          <Route path="/DO/report" element={authorizedElement(<ReportDOPage />, logged)} />
          {/* DSO */}
          <Route path="/DSO/detail" element={authorizedElement(<DSODetailPage />, logged)} />
          <Route path="/DSO/list/:status" element={authorizedElement(<DSOListPage />, logged)} />
          {/* PURCHASE REQUISITION */}
          {/* <Route path="/PR/create" element={authorizedElement(<CreatePurchaseRequisitionPage/>, logged)}/>
      <Route path="/PR/detail" element={authorizedElement(<DetailPurchaseRequisitionPage/>, logged)}/>
      <Route path="/PR/list" element={authorizedElement(<ListPurchaseRequisitionPage/>, logged)}/>
      <Route path="/PR/edit" element={authorizedElement(<EditPurchaseRequisitionPage/>, logged)}/> */}
          {/* PURCHASE ORDER */}
          {/* <Route path='/PO/create' element={authorizedElement(<CreatePurchaseOrderPage/>, logged)}/>
      <Route path='/PO/list' element={authorizedElement(<ListPurchaseOrderPage/>, logged)}/>
      <Route path='/PO/detail' element={authorizedElement(<DetailPurchaseOrderPage/>, logged)}/>
      <Route path='/PO/edit' element={authorizedElement(<EditPurchaseOrderPage/>, logged)}/> */}
          {/* GOODS RECEIPT PO NOTE */}
          {/* <Route path='/GR-PO/create' element={authorizedElement(<GoodsReceiptNotePageCreate/>, logged)}/>
      <Route path='/GR-PO/detail' element={authorizedElement(<GoodsReceiptNoteDetailPage/>, logged)}/>
      <Route path='/GR-PO/list' element={authorizedElement(<GoodsReceiptNoteListPage/>, logged)}/>
      <Route path='/GR-PO/edit' element={authorizedElement(<GoodsReceiptNoteEditPage/>, logged)}/> */}
          {/* INVOICE RECEIPT PO */}
          {/* <Route path='/INVR-PO/create' element={authorizedElement(<InvoiceReceiptCreatePage/>, logged)}/>
      <Route path='/INVR-PO/list' element={authorizedElement(<InvoiceReceiptListPage/>, logged)}/>
      <Route path='/INVR-PO/detail' element={authorizedElement(<InvoiceReceiptDetailPage/>, logged)}/>
      <Route path='/INVR-PO/edit' element={authorizedElement(<InvoiceReceiptEditPage/>, logged)}/> */}
          {/* INVOICE PAYMENT */}
          {/* <Route path='/INVP-PO/create' element={authorizedElement(<CreateINVPPage/>, logged)}/>
      <Route path='/INVP-PO/detail' element={authorizedElement(<DetailINVPPage/>, logged)}/>
      <Route path='/INVP-PO/edit' element={authorizedElement(<EditINVPPage/>, logged)}/>
      <Route path='/INVP-PO/list' element={authorizedElement(<ListINVPPage/>, logged)}/> */}
          {/* ReadExcelFilePage */}
          {/* <Route path='/excel/to/accurate' element={authorizedElement(<ExcelToAccuratePage/>, logged)}/> */}
          {/* SALDO AWAL */}
          <Route path="/saldoawal/piutang" element={authorizedElement(<PageSaldoAwalPiutang />, logged)} />
          <Route path="/mapping/item" element={authorizedElement(<MappingItemToAccuratePage />, logged)} />
          <Route path="accurate/*" element={authorizedElement(<AccurateMainPage />, logged)} />
          {/* SELLER */}
          <Route path="SELLER/*" element={authorizedElement(<MasterSellerRoute />, logged)} />
          <Route path="FP-DO/*" element={authorizedElement(<DepositOrderInvoiceRoute />, logged)} />
          {/* PROMO */}
          <Route path="PROMO/*" element={authorizedElement(<PromoModuleRoute />, logged)} />
          {/* NOT FOUND */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );

  if (!logged) {
    return routing();
  }

  return (
    <MyContext.Provider value={{ current_login_user: user }}>
      <LDTheme>
        <MainDrawer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>{routing()}</LocalizationProvider>
        </MainDrawer>
      </LDTheme>
    </MyContext.Provider>
  );
}

export default App;
