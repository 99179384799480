import { Badge, Box, Divider, Paper, Stack, Typography, styled } from "@mui/material";
import { forwardRef } from "react";
import NumberFormat from "react-number-format";

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export const CenterComponent = styled("div")((props) => ({
  // minHeight: 'calc(100vh - 64px - 76px)',
  // padding: props.theme.spacing(0, 2),
  // minHeight: props.withHeaderNav ? 'calc(100svh - 64px - 64px - 137px)' : 'calc(100svh - 64px - 137px)',
  minHeight: `calc(100svh - 200px)`,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: props.textAlign || "unset",
}));

export const RupiahNumberFormatField = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  // const format = (numStr) => {
  //     if (numStr === "") return "";
  //     return new Intl.NumberFormat("in-ID", {
  //         style: "currency",
  //         currency: "IDR",
  //     }).format(numStr);
  // };

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      //  format={format}
      //  prefix="Rp "
    />
  );
});

export const NumberFormatField = forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  // const format = (numStr) => {
  //     if (numStr === "") return "";
  //     return new Intl.NumberFormat("in-ID").format(numStr);
  // };
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
    />
  );
});

export const MyFlexibleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || "normal"};
  margin-left: ${(props) => props.theme.spacing(0 - (props.spacing || 0))};
  margin-top: ${(props) => props.theme.spacing(0 - (props.spacing || 0))};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "unset")};
  & .ld-flexible-item-wrap-space {
    padding-top: ${(props) => props.theme.spacing(props.spacing || 0)};
    padding-left: ${(props) => props.theme.spacing(props.spacing || 0)};
    ${(props) => props.theme.breakpoints.only("xs")} {
      width: ${(props) => (props.maxWidthOnMobile ? "100%" : "auto")};
    }
    display: flex;
  }
`;

export const MyFlexibleItem = ({ alignItems, fullWidth, ...other }) => (
  <div
    className="ld-flexible-item-wrap-space"
    style={{
      alignItems: alignItems || "center",
      ...(fullWidth && {
        width: "100%",
      }),
    }}
  >
    {other.children}
  </div>
);

export const FlexibleContainer = styled("div")(({ theme, spacing }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  marginTop: spacing ? theme.spacing(0 - spacing) : null,
  marginLeft: spacing ? theme.spacing(0 - spacing) : null,
  "& .my-flexible-item-wrap-space": {
    paddingTop: spacing ? theme.spacing(spacing) : null,
    paddingLeft: spacing ? theme.spacing(spacing) : null,
  },
}));

export const FlexibleItem = forwardRef(({ width, maxWidth, children, ...other }, ref) => (
  <Box ref={ref} className="my-flexible-item-wrap-space" width={width} maxWidth={maxWidth} {...other}>
    {children}
  </Box>
));

export const DashboardErrorPage = styled(Paper)(({ theme }) => ({
  height: 184,
  padding: theme.spacing(1),
  borderRadius: 10,
  backgroundColor: theme.palette.background.paper,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

export const LabelWithValue = ({
  label,
  value,
  icon,
  spacingLabel = 0.5,
  color,
  labelFontSize,
  fontSize = "1em",
  columnDirection = false,
  useDividerOnMobileSize = false,
  fontWeight = 600,
  align,
  useBadge = false,
  badgeColor,
}) => (
  <>
    <Box
      display="flex"
      flexDirection={
        columnDirection
          ? "column"
          : {
              xs: "row",
              sm: "column",
              md: "column",
              lg: "column",
              xl: "column",
            }
      }
      justifyContent={
        columnDirection
          ? "normal"
          : {
              xs: "space-between",
              sm: "normal",
              md: "normal",
              lg: "normal",
              xl: "normal",
            }
      }
      alignItems={
        columnDirection
          ? "normal"
          : {
              xs: "center",
              sm: "normal",
              md: "normal",
              lg: "normal",
              xl: "normal",
            }
      }
    >
      <Typography
        component="div"
        fontSize={labelFontSize || "0.85em"}
        color="GrayText"
        sx={{ mr: { xs: "8px", sm: 0, md: 0, lg: 0, xl: 0 } }}
        align={align}
      >
        {icon ? (
          <Stack spacing={spacingLabel} direction="row" alignItems="center">
            <span>{label}</span>
            {icon}
          </Stack>
        ) : (
          label
        )}
      </Typography>
      {useBadge ? (
        <Badge
          variant="dot"
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          sx={{
            "& .MuiBadge-badge": {
              top: 10,
              left: -10,
              background: badgeColor,
            },
          }}
        >
          <Typography component="div" fontSize={fontSize} fontWeight={fontWeight} color={color} align={align}>
            {value}
          </Typography>
        </Badge>
      ) : (
        <Typography component="div" fontSize={fontSize} fontWeight={fontWeight} color={color} align={align}>
          {value}
        </Typography>
      )}
    </Box>
    {useDividerOnMobileSize && (
      <Divider
        sx={{
          display: {
            xs: "block",
            sm: "none",
            md: "none",
            lg: "none",
            xl: "none",
          },
        }}
      />
    )}
  </>
);

export const PaperForm = styled(Paper)((props) => ({
  width: "100%",
  maxWidth: ["sm", "md", "lg", "xl"].includes(props.maxWidth) ? props.theme.breakpoints.values[props.maxWidth] : props.maxWidth,
  background: props.theme.palette.background.paper,
  padding: props.theme.spacing(2),
  borderRadius: 8,
  // boxShadow: props.theme.shadows[typeof props.elevation !== 'number' ? 2 : props.elevation],
  [props.theme.breakpoints.only("xs")]: {
    borderRadius: 0,
    maxWidth: "100%",
    padding: props.theme.spacing(2, 1),
    boxShadow: props.theme.shadows[0],
    border: "none",
  },
}));
