import { ORIGIN } from "Config/url_api";
import axios from "axios";
import Decimal from "decimal.js";
import moment from "moment";
import Cookies from "universal-cookie";

const axiosInstance = axios.create({
  baseURL: ORIGIN,
});

const getToken = () => {
  const cookies = new Cookies();
  // const tok = cookies.get('token')
  const tok = cookies.get("ld_token");
  return tok;
};

function getData(url = "", useCache = false, cacheDurationInMinute = 1, searchParams, data) {
  const token = getToken();
  const headers = {
    Authorization: token,
  };
  if (useCache) {
    headers["Cache-Control"] = `private, max-age=${cacheDurationInMinute * 60}`;
  }
  return axiosInstance
    .get(url, {
      headers,
      params: searchParams,
      data,
    })
    .then((res) => res.data);
}

function postData(url = "", data, searchParams) {
  return axiosInstance
    .post(url, data, {
      headers: {
        Authorization: getToken(),
      },
      params: searchParams,
    })
    .then((res) => res.data);
}

function putData(url = "", data, searchParams) {
  return axiosInstance
    .put(url, data, {
      headers: {
        Authorization: getToken(),
      },
      params: searchParams,
    })
    .then((res) => res.data);
}

function deleteData(url = "", data, searchParams) {
  return axiosInstance
    .delete(url, {
      data: data,
      headers: {
        Authorization: getToken(),
      },
      params: searchParams,
    })
    .then((res) => res.data);
}

/**
 * @param {number|bigint} x
 * @param {number} fraction
 * @param {number} minimumFractionDigits
 * @returns
 */
function numberWithCommas(x, maximumFractionDigits = 2, minimumFractionDigits) {
  // if (x === null || x === undefined || isNaN(x)) return "-";
  // if (checkdecimal && x % 1 === 0) x = parseInt(x);
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return new Intl.NumberFormat('in-ID', {minimumFractionDigits: 2}).format(x);
  return new Intl.NumberFormat("id-ID", {
    maximumFractionDigits: maximumFractionDigits || 2,
    minimumFractionDigits,
  }).format(x);
}

function getPriceAfterDiscount(price = 0, discount = 0) {
  // return ((discount / 100) * price);
  return new Decimal(discount).div(100).times(price).toNumber();
}

function calculateDiscountValue(originalPrice, discountPercentage) {
  originalPrice = Number(originalPrice);
  discountPercentage = Number(discountPercentage);
  if (originalPrice <= 0 || discountPercentage < 0) {
    throw new Error("Original price must be greater than 0 and discount percentage cannot be negative");
  }

  // Menggunakan Decimal.js untuk perhitungan presisi tinggi
  const price = new Decimal(originalPrice);
  const percentage = new Decimal(discountPercentage);
  const discountValue = price.mul(percentage).div(100);

  return discountValue.toNumber(); // Mengembalikan nilai sebagai angka
}

function formatToRupiahCurr(val = 0, nDecimal, withoutSign) {
  // Only 2 decimal
  // if (!(!val && val !== 0))
  //     val = (val - 0).toFixed(nDecimal);
  // return `Rp.${numberWithCommas(val)}`;
  if (!val && typeof val !== "number") return "Rp -";
  const result = new Intl.NumberFormat("id-ID", {
    currency: "IDR",
    style: "currency",
    maximumFractionDigits: typeof nDecimal === "number" ? nDecimal : 2,
    minimumFractionDigits: typeof nDecimal === "number" ? nDecimal : 2,
  }).format(val);
  if (withoutSign) return result.slice(3);
  return result;
}

function formatToRupiahCurrVer2(
  val = 0,
  options = {
    decimal: 2,
    withoutSign: false,
    isComponentFormat: false,
  }
) {
  if (!val && typeof val !== "number") return "Rp -";

  let decimal = typeof options.decimal === "number" ? options.decimal : 2;
  let result = new Intl.NumberFormat("id-ID", {
    currency: "IDR",
    style: "currency",
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  }).format(val);

  // PISAHIN ANTARA DESIMAL DAN NON-DESIMAL

  if (options.isComponentFormat) {
    let [integerPart, decimalPart] = result.split(",");
    if (options.withoutSign) {
      integerPart = integerPart.slice(3);
    }
    return (
      <span style={{ fontSize: "1em" }}>
        <b>{integerPart}</b>,<span style={{ fontSize: "0.75em" }}>{decimalPart}</span>
      </span>
    );
  }

  if (options.withoutSign) return result.slice(3);
  return result;
}

function sortArrayByDateType(array = [], propName = "", sort = "") {
  if (!array && !Array.isArray(array)) return array;
  return array.sort((a, b) => {
    if (sort === "desc") return new Date(b[propName]) - new Date(a[propName]);
    return new Date(a[propName]) - new Date(b[propName]);
  });
}

function sortASC(array, key) {
  if (!Array.isArray(array) || !key) return array;
  return array.sort((a, b) => {
    if (a[key] === null) {
      return 1;
    }
    if (b[key] === null) {
      return -1;
    }
    return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
  });
}

function sortDESC(array, key) {
  if (!Array.isArray(array) || !key) return array;
  return array.sort((a, b) => {
    if (a[key] === null) {
      return 1;
    }
    if (b[key] === null) {
      return -1;
    }
    return a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0;
  });
}

function formatArrayObjToObject(array = [], keyProperty = "") {
  const results = {};
  if (!Array.isArray(array) || typeof keyProperty !== "string" || !keyProperty) return results;

  array.forEach((val) => {
    results[val[keyProperty]] = val;
  });
  return results;
}

const filterArrayOfObject = (arr = [], filter = "") => {
  if (!Array.isArray(arr)) return arr;
  if (filter === "") return arr;
  filter = filter.toLowerCase();

  return arr.filter((ar) =>
    Object.values(ar).some((val) => {
      if (typeof val === "number") val = val.toString();
      if (!(typeof val === "string" && typeof val === "string")) return false;
      return val.toLowerCase().includes(filter.toLowerCase());
    })
  );
};

const getErrorFetchMsg = (error) => {
  // if (error.response && error.response.status === 404) {
  //     return "URL Not Found";
  // }
  if (error.response && error.response.data && typeof error.response.data === "object") {
    return error.response.data;
  }
  if (error.response && error.response.data && typeof error.response.data === "string") {
    if (error.response.data.includes("<html>")) {
      if (error.response.status === 404) return "URL Not Found";
      // return 'URL Not Found';
      return `Error Code: ${error.response.status}`;
    }
    return error.response.data || "Unknown Error";
  } else {
    if (error.response && error.response.status === 404) return "URL Not Found";
    return error.message || "Unknown Error";
  }
};

const formatErrorFetch = (error) => ({
  status: error.response && error.response.status ? error.response.status : 500,
  message: getErrorFetchMsg(error),
});

function formatSizeToMB(size) {
  size = size / 1024 / 1024;
  return `File size ${size.toFixed(2)} MB`;
}

function acronymUnitName(unit) {
  if (unit === "Gross") return "Grs";
  if (unit === "Lusin") return "Lsn";
  if (unit === "Set") return "Set";
  if (unit === "Piece") return "Pcs";
  return unit;
}

function acronymPackName(unit) {
  if (unit === "Bal") return "Bal";
  if (unit === "Dus") return "Dus";
  if (unit === "Ikat") return "Ikt";
  if (unit === "Piece") return "Pcs";
  return unit;
}

function getErrorFetchResponse(error) {
  return {
    status: error.response && error.response.status ? error.response.status : 500,
    statusCode: error.response && error.response.status ? error.response.status : 500,
    message: getErrorFetchMsg(error),
  };
}

function handleErrorResponse(error) {
  const result = {
    statusCode: (error.response && error.response.status) || 500,
    message: null,
    errors: null,
  };
  if (error.response && error.response.data) {
    if (error.response.data.message) {
      result.message = error.response.data.message;
    }
    if (error.response.data.errors) {
      result.errors = error.response.data.errors;
    }
    if (!error.response.data.message && !error.response.data.errors) {
      result.message = error.response.statusText || "UNKNOWN ERROR";
    }
  } else {
    result.message = error.message;
  }
  return result;
}

function getDiscPercentage(nilai_sebelum_diskon, nilai_sesudah_diskon) {
  nilai_sebelum_diskon -= 0;
  nilai_sesudah_diskon -= 0;
  return ((nilai_sebelum_diskon - nilai_sesudah_diskon) / nilai_sebelum_diskon) * 100;
}

function setToStartOfDay(date) {
  if (date === "") return new Date("");
  const temp_date = date ? new Date(date) : new Date();
  temp_date.setHours(0, 0, 0, 0);
  return temp_date;
}

function setToMiddleOfDay(date) {
  if (date === "") return new Date("");
  const temp_date = date ? new Date(date) : new Date();
  temp_date.setHours(12, 0, 0, 0);
  return temp_date;
}

function setToEndOfDay(date) {
  if (date === "") return new Date("");
  const temp_date = date ? new Date(date) : new Date();
  temp_date.setHours(23, 59, 59, 999);
  return temp_date;
}

function hitungPajak(harga, persenPajak) {
  return harga * (persenPajak / 100);
}

function hitungHargaSebelumPajak(harga, persenPajak) {
  return harga / (1 + persenPajak / 100);
}

function hitungHargaSetelahPajak(harga, persenPajak) {
  return harga * (1 + persenPajak / 100);
}

function getElementValueByID(elementID = "") {
  const el = document.getElementById(elementID);
  if (!el) return undefined;
  return el.value;
}

function getValueElementByID(elementID = "") {
  const el = document.getElementById(elementID);
  if (!el) return undefined;
  return el.value;
}

function removeCommas(input = "") {
  return input.replace(/,/g, "");
}

function formatIdrCurrToString(input = "") {
  return input.replace(/\./g, "").replace(",", ".");
}

function bulatkanDuaDigit(input) {
  // return Number((input - 0).toFixed(2));
  input = Number(input);
  return Number(
    removeCommas(
      Intl.NumberFormat("en-EN", {
        maximumFractionDigits: 2,
      }).format(input)
    )
  );
}

function bulatkanIdrCurrToNumber(input = "") {
  input = formatIdrCurrToString(input);
  return bulatkanDuaDigit(input);
}

function parseCurrToNumber(inputString) {
  if (!inputString) return inputString;
  // Menghapus tanda titik sebagai pemisah ribuan dan koma sebagai pemisah desimal
  const cleanedString = inputString.replace(/\./g, "").replace(",", ".");

  // Mengubah string menjadi angka
  const parsedNumber = Number(cleanedString);

  return parsedNumber;
}

function generateQueryString(obj) {
  if (!obj) return obj;
  return Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join("&");
}

function mergeArraysByProperty(arr1, arr2, property) {
  // Menggabungkan kedua array tanpa duplikat berdasarkan property
  const mergedArray = arr1.concat(arr2.filter((item2) => !arr1.some((item1) => item1[property] === item2[property])));

  return mergedArray;
}

/**
 *
 * @param {String} value
 * @param {Number} fraction
 * @returns
 */
function limitDecimalInput(value, fraction = 2) {
  // Mengganti semua karakter non-desimal dengan kosong
  value = value.replace(/[^0-9.]/g, "");

  // Memisahkan bagian desimal
  const parts = value.split(".");

  // Mengambil bagian depan (sebelum koma) dan bagian belakang (setelah koma)
  const front = parts[0];
  const back = parts[1];

  // Membatasi bagian belakang (setelah koma) menjadi dua digit
  if (back !== undefined) {
    value = `${front}.${back.slice(0, fraction)}`;
  }
  return value;
}

function mapArrayIntoObj(array, fieldKey) {
  if (!Array.isArray(array)) return array;
  const result = {};
  array.forEach((el) => {
    const key = el[fieldKey];
    if (!key) return;

    result[key] = el;
  });
  return result;
}

/**
 * Fungsi untuk memeriksa apakah dua nilai masih masuk dalam toleransi selisih.
 * @param {number} nilai1 - Nilai pertama.
 * @param {number} nilai2 - Nilai kedua.
 * @param {number} toleransi - Toleransi selisih yang diizinkan.
 * @returns {boolean} - Mengembalikan true jika nilai masih dalam toleransi, false sebaliknya.
 */
function cekToleransiSelisih(nilai1, nilai2, toleransi) {
  // Menghitung selisih antara dua nilai
  const selisih = Math.abs(nilai1 - nilai2);

  // Memeriksa apakah selisih masih dalam toleransi yang diizinkan
  return selisih <= toleransi;
}

// Fungsi untuk membuat nested array dengan panjang tertentu
function createNestedArray(array, chunkSize, addEmptyObject = false) {
  const nestedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    nestedArray.push(array.slice(i, i + chunkSize));
  }

  // Menambahkan objek kosong jika perlu
  if (addEmptyObject) {
    const lastChunk = nestedArray[nestedArray.length - 1];
    if (lastChunk.length < chunkSize) {
      const remaining = chunkSize - lastChunk.length;
      for (let i = 0; i < remaining; i++) {
        lastChunk.push({});
      }
    }
  }

  return nestedArray;
}

function isBetween(value, minValue, maxValue) {
  value = Number(value);
  minValue = Number(minValue);
  maxValue = Number(maxValue);
  return value >= minValue && value <= maxValue;
}

function isStrictlyBetween(value, minValue, maxValue) {
  return value > minValue && value < maxValue;
}

/**
 * Memecah sebuah array menjadi beberapa array sesuai jumlah maksimum indeks.
 *
 * @param {Array} array - Array yang akan dipecah.
 * @param {number} chunkSize - Jumlah maksimum indeks dalam setiap array pecahan.
 * @returns {Array} - Array dari array-array pecahan.
 */
function chunkArray(array, chunkSize) {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunkedArray.push(array.slice(i, i + chunkSize));
  }
  return chunkedArray;
}

const getDateErrorMessage = (errorDate) => {
  let result = null;
  if (errorDate) {
    result = errorDate;
    if (errorDate === "invalidDate") {
      result = "Tanggal tidak valid";
    }
    if (errorDate === "disableFuture") {
      result = "Maksimal hari ini";
    }
    if (errorDate === "minDate") {
      result = "Tanggal minimum";
    }
    if (errorDate === "maxDate") {
      result = "Tanggal maksimum";
    }
  }
  return result;
};

function generateJsonFromFormData(
  formData,
  options = {
    validateError: false,
    errorElementName: "",
    preventElementName: "",
  }
) {
  options = options || {};
  const json = {};
  formData.forEach((value, key) => {
    const parts = key.split(".");
    let current = json;
    if (options.preventElementName) {
      if (typeof options.preventElementName === "string") {
        if (options.preventElementName === key) return;
      }
      if (Array.isArray(options.preventElementName)) {
        if (options.preventElementName.includes(key)) return;
      }
    }
    if (options.validateError === true) {
      if (options.errorElementName === key) {
        value = value.toString().trim();
        if (value) {
          throw Error(value);
        }
      }
    }
    parts.forEach((propName, i) => {
      const isLastPart = i + 1 === parts.length;
      if (isLastPart) {
        if (current[propName] !== undefined) {
          if (typeof current[propName] === "object") return;
        }
        current[propName] = value;
      } else {
        if (current[propName] === undefined) {
          current[propName] = {};
        }
        current = current[propName];
      }
    });
  });
  return json;
}

/**
 * Menghilangkan semua spasi dari sebuah string.
 *
 * @param {string} str - String yang akan dihilangkan spasinya.
 * @returns {string} - String tanpa spasi.
 */
function removeSpaces(str) {
  if (!str) return str;
  return str.replace(/\s+/g, "");
}

function hasDecimal(value) {
  return value % 1 !== 0;
}

function normalizeText(text = "") {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "")
    .trim();
}

function getTimeDuration(date1, date2, inLabel = false) {
  const start = moment(date1);
  const end = moment(date2);
  const duration = moment.duration(end.diff(start));

  const objResult = {
    years: duration.years(),
    months: duration.months(),
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };

  if (inLabel) {
    const labelResult = [];
    if (objResult.years) {
      labelResult.push(`${objResult.years} tahun`);
    }
    if (objResult.months) {
      labelResult.push(`${objResult.months} bulan`);
    }
    if (objResult.days) {
      labelResult.push(`${objResult.days} hari`);
    }
    if (objResult.hours) {
      labelResult.push(`${objResult.hours} jam`);
    }
    if (objResult.minutes) {
      labelResult.push(`${objResult.minutes} menit`);
    }
    if (objResult.seconds) {
      labelResult.push(`${objResult.seconds} detik`);
    }

    return labelResult.join(" ");
  }
  return objResult;
}

function hitungNilaiDiskon(harga = 0, persentaseDiskon = 0) {
  return new Decimal(harga).times(new Decimal(persentaseDiskon).dividedBy(100)).toNumber();
}

function cloneObject(obj) {
  return {
    ...obj,
  };
}

function deepCloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}
/**
 * @param {[]} arr
 * @param {number} index
 * @returns
 */
function removeElementByIndex(arr, index) {
  arr = [...arr];
  if (index >= 0 && index < arr.length) {
    arr.splice(index, 1);
  }
  return arr;
}

function priceEquals(price1, price2, nDecimal = 2) {
  return new Decimal(price1).toFixed(nDecimal) === new Decimal(price2).toFixed(nDecimal);
}
function priceNotEquals(price1, price2, nDecimal = 2) {
  return !priceEquals(price1, price2, nDecimal);
}

function createArray(length) {
  if (length < 0) {
    throw new Error("Length must be a non-negative number.");
  }

  return Array.from(
    {
      length,
    },
    (_, index) => index
  );
}

function isEmptyValue(val) {
  return val === undefined || val === null;
}

function isValidDateFormat(dateString) {
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
  return datePattern.test(dateString);
}

export {
  acronymPackName,
  acronymUnitName,
  axiosInstance,
  bulatkanDuaDigit,
  bulatkanIdrCurrToNumber,
  calculateDiscountValue,
  cekToleransiSelisih,
  chunkArray,
  cloneObject,
  createArray,
  createNestedArray,
  deepCloneObject,
  deleteData,
  filterArrayOfObject,
  formatArrayObjToObject,
  formatErrorFetch,
  formatIdrCurrToString,
  formatSizeToMB,
  formatToRupiahCurr,
  formatToRupiahCurrVer2,
  generateJsonFromFormData,
  generateQueryString,
  getData,
  getDateErrorMessage,
  getDiscPercentage,
  getElementValueByID,
  getErrorFetchMsg,
  getErrorFetchResponse,
  getPriceAfterDiscount,
  getTimeDuration,
  getToken,
  getValueElementByID,
  handleErrorResponse,
  hasDecimal,
  hitungHargaSebelumPajak,
  hitungHargaSetelahPajak,
  hitungNilaiDiskon,
  hitungPajak,
  isBetween,
  isEmptyValue,
  isStrictlyBetween,
  isValidDateFormat,
  limitDecimalInput,
  mapArrayIntoObj,
  mergeArraysByProperty,
  normalizeText,
  numberWithCommas,
  parseCurrToNumber,
  postData,
  priceEquals,
  priceNotEquals,
  putData,
  removeCommas,
  removeElementByIndex,
  removeSpaces,
  setToEndOfDay,
  setToMiddleOfDay,
  setToStartOfDay,
  sortArrayByDateType,
  sortASC,
  sortDESC,
};
