import { CircularProgress } from "@mui/material";
import ErrorBoundary from "Components/ErrorBoundary";
import { CenterComponent } from "Components/Utilites";
import NotFoundPage from "Page/NotFoundPage";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// const PageCreateSalesOrder = lazy(() => import('./CreateSalesOrderPage'));
// const PageCreateSalesOrderNew = lazy(() => import("./CreateSalesOrderPageNEW"));// *INi kalo PROMO SUDAH JALAn
const PageCreateSalesOrderNew = lazy(() => import("./CreateSOPageNEWTemp"));
// const EditSalesOrderPageNEW = lazy(() => import("./EditSalesOrderPageNEW"));
// const EditSalesOrderPageNEW = lazy(() => import("./EditSalesOrderPageV2")); // *INi kalo PROMO SUDAH JALAn
const EditSalesOrderPageNEW = lazy(() => import("./EditSOPageV2Temp"));

// const PageDetailSalesOrder = lazy(() => import("./DetailSalesOrderPage"));
const PageDetailSalesOrderNEW = lazy(() => import("./DetailSalesOrderNEWPage"));
const PageSalesOrderList = lazy(() => import("./SalesOrderListPage"));
// const PageEditSalesOrder = lazy(() => import('./EditSalesOrderPage'));
const ReportSalesOrderPage = lazy(() => import("./ReportSalesOrderPage"));
const ListOrderForWarehousePage = lazy(() => import("./ListOrderForWarehousePage"));

const FallBackComponent = () => (
  <CenterComponent>
    <CircularProgress />
  </CenterComponent>
);

const SalesOrderRoute = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<FallBackComponent />}>
        <Routes>
          {/* <Route path="create" element={<PageCreateSalesOrder/>}/> */}
          <Route path="create" element={<PageCreateSalesOrderNew />} />
          <Route path="List/:status" element={<PageSalesOrderList />} />
          {/* <Route path="Detail" element={<PageDetailSalesOrder />} /> */}
          <Route path="detail" element={<PageDetailSalesOrderNEW />} />
          {/* <Route path="Edit" element={<PageEditSalesOrder/>}/> */}
          {/* <Route path="edit" element={<EditSalesOrderPageNEW />} /> */}
          <Route path="edit" element={<EditSalesOrderPageNEW />} />
          <Route path="report" element={<ReportSalesOrderPage />} />
          <Route path="listwh/:status" element={<ListOrderForWarehousePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
};

export default SalesOrderRoute;
